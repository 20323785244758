import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import { BodyLarge } from "../components/typography"
import colors from "../styles/colors"
import styled from "styled-components"
import StoreLocator from "../components/store-locator/Store-Locator"
import Seo from "../components/seo"

const Wrapper = styled.div`
  color: ${colors.creamLight};
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 75%
  );
  background-position: bottom;
  background-size: cover;

  display: flex;
  justify-content: center;

  align-items: center;

  > div {
    text-align: center;

    ${BodyLarge} {
      margin-bottom: 24px;
    }

    a {
      margin-top: 40px;
    }
  }
`

const query = graphql`
  query StoreQuery {
    allContentfulStores {
      nodes {
        address {
          raw
        }
        location {
          lat
          lon
        }
        name
        type
      }
    }
  }
`

export function Head() {
  return <Seo title={"Store Locator"} />
}

export default function GenericTemplate() {
  const data = useStaticQuery(query)

  return (
    <Layout>
      <Wrapper>
        <StoreLocator data={data} />
      </Wrapper>
    </Layout>
  )
}
