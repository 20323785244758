import React, { useRef, useEffect, useState } from "react"
import bbox from "@turf/bbox"
import { multiPoint } from "@turf/helpers"
import "mapbox-gl/dist/mapbox-gl.css"

import mapboxgl from "mapbox-gl"

const MAPBOX_TOKEN =
  "pk.eyJ1IjoibW0zMzEiLCJhIjoiY2x5Njdwbmt2MDdleTJxb2NzanI2aHd2OCJ9.4x8myWHMI6JSa3F7D8ZWmw"

const mapContainerStyle = {
  width: "100%",
  height: "100%",
  minHeight: "410px",
}

const Map = ({ places, selectedPlace }) => {
  const mapContainerRef = useRef(null)

  const [map, setMap] = useState(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/mm331/cly7nn67200hz01r4507q7zob",
      // Empire State Building [lng, lat]
      center: [-73.9856, 40.7497],
      zoom: 10,
    })
    // map.addControl(new mapboxgl.NavigationControl(), "top-right")

    for (const place of places) {
      // create a HTML element for each feature
      const el = document.createElement("div")
      el.className = "marker"

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el, { offset: [0, -25] })
        .setLngLat(place.location)
        .addTo(map)
    }

    setMap(map)

    return () => map.remove()
  }, [])

  useEffect(() => {
    if (!map) return

    if (places.length !== 0) {
      const coords = []
      places.forEach(place => {
        coords.push([place.location.lon, place.location.lat])
      })
      const feature = multiPoint(coords)
      const box = bbox(feature)

      map.fitBounds(
        [
          [box[0], box[1]],
          [box[2], box[3]],
        ],
        {
          padding: 20,
          maxZoom: 14,
          duration: 2000,
        }
      )
    } else {
      map.easeTo({
        center: [-73.9856, 40.7497],
        zoom: 10,
        duration: 2000,
      })
    }
  }, [map, places])

  useEffect(() => {
    if (!map) return

    map.easeTo({
      center: selectedPlace.location,
      zoom: 14,
      duration: 2000,
    })
  }, [selectedPlace])

  return <div ref={mapContainerRef} style={mapContainerStyle} />
}

export default Map
