import React, { useEffect, useState } from "react"
import styled from "styled-components"
import distance from "@turf/distance"

import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import typography from "../../styles/typography"
import { Header2 } from "../typography"
import PrimaryButton from "../common/PrimaryButton"
import MarkerImg from "../../images/marker.svg"
import richText from "../../utils/richText"

const Wrapper = styled.div`
  max-width: 400px;

  margin: 0 auto;

  position: relative;

  padding: 0 0 20px;

  color: ${colors.creamLight};

  ${Header2} {
    padding-bottom: 29px;
    border-bottom: 1px solid #f8f0d8;
    text-align: left;

    font-size: 40px;
  }

  @media ${breakpoints.tablet} {
    padding: 115px 0;

    ${Header2} {
      font-size: 48px;
    }
  }

  @media ${breakpoints.laptop} {
    padding: 108px 0;

    ${Header2} {
      font-size: 56px;
    }
  }
`

const InputWrapper = styled.div`
  margin-top 24px;
  display: flex;

  input {
    border: #F8F0D8 solid 1px;
    background: none;
    margin-right: 10px;
    width: 100%;
    min-width: 200px;
    color: ${colors.creamLight};
    text-align: center;
    
    ::placeholder {
      color: ${colors.creamLight};
      text-align: center;
    }
  }

  button {
    color: ${colors.firDark};
  }

  @media ${breakpoints.tablet} {
    margin-top 32px;
  }
`

const FilterWrapper = styled.div`
  text-align: left;
  margin: 18px 0 12px;

  ${typography.body.desktop}

  .css-checkbox {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  .css-checkbox + label {
    position: relative;
    font-size: 14px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 16px;
    color: rgb(248, 240, 216);
    margin-right: 16px;
  }
  .css-checkbox + label::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    width: 12px;
    height: 12px;
    background-color: rgb(2, 11, 8);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(248, 240, 216);
    border-radius: 2px;
    box-shadow: none;
  }
  .css-checkbox:checked + label::after {
    content: " ";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZjhmMGQ4IiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+");
    background-repeat: no-repeat;
    background-size: 8px 8px;
    background-position: center center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    left: 0px;
    top: 0px;
    text-align: center;
    background-color: transparent;
    font-size: 10px;
    height: 15px;
    width: 14px;
  }

  @media ${breakpoints.tablet} {
    margin: 26px 0 12px;
  }
`

const ListWrapper = styled.div`
  max-height: 333px;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`

const PlaceWrapper: any = styled.div`
  border-bottom: #f8f0d8 solid 1px;
  margin-bottom: 12px;

  display: flex;
  flex-direction: row;
  cursor: pointer;
`

const MarkerWrapper = styled.div`
  padding-top: 2px;
  width: 50px;

  display: flex;
  flex-direction: column;

  ${typography.body.desktop}
  font-size: 11px;
  font-weight: bold;

  img {
    width: 28px;
    height: auto;

    margin-bottom: 4px;
  }
`

const Distance = styled.div`
  ${typography.body.desktop}
  font-size: 10px;
  font-weight: bold;
  text-align: left;
`

const DetailsWrapper = styled.div`
  text-align: left;
`

const PlaceTitle = styled.div`
  ${typography.bodyMedium.desktop}
  font-size: 16px;
  margin-bottom: 8px;
`

const PlaceAddress = styled.div`
  p {
    ${typography.metaSmall.desktop}
  }
`

const PlaceType = styled.div`
  ${typography.metaSmall.desktop}
  color: ${colors.orangeDark};
  margin: 8px 0 12px;
`

export default function ListComponent({ places, handlePlaceClick }) {
  const [zipCode, setZipCode] = useState("")
  const [filterRetail, setFilterRetail] = useState(false)
  const [filterRestaurant, setFilterRestaurant] = useState(false)
  const [filteredPlaces, setFilteredPlaces] = useState(places)

  function handleInput(e) {
    const re = /^[0-9\b]+$/

    if (e.target.value === "" || re.test(e.target.value)) {
      setZipCode(e.target.value)
    }
  }

  function handleFilterRetail() {
    setFilterRetail(!filterRetail)
  }

  function handleFilterRestaurant() {
    setFilterRestaurant(!filterRestaurant)
  }

  async function handleSearch() {
    if (zipCode.length !== 5) return

    let search = []
    await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${zipCode}.json?country=US&access_token=pk.eyJ1IjoibW0zMzEiLCJhIjoiY2x5Njdwbmt2MDdleTJxb2NzanI2aHd2OCJ9.4x8myWHMI6JSa3F7D8ZWmw`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        search = resultData.features[0]?.center
      })

    if (!search) return

    const temp = filteredPlaces.map(place => {
      return {
        ...place,
        distance: distance(search, [place.location.lon, place.location.lat], {
          units: "miles",
        }).toFixed(2),
      }
    })
    setFilteredPlaces(temp)
  }

  useEffect(() => {
    const filters = []
    filterRetail && filters.push("retail")
    filterRestaurant && filters.push("restaurant")

    const temp = places
    if (filters.length > 0)
      setFilteredPlaces(temp.filter(place => filters.includes(place.type)))
    else setFilteredPlaces(temp)
  }, [places, filterRetail, filterRestaurant])

  return (
    <Wrapper>
      <Header2>Store Locator</Header2>
      <InputWrapper>
        <input
          type="text"
          maxLength={5}
          value={zipCode}
          onChange={handleInput}
          onKeyUp={event => {
            // If the user presses the "Enter" key on the keyboard
            if (event.key === "Enter") {
              // Cancel the default action, if needed
              event.preventDefault()
              handleSearch()
            }
          }}
          placeholder="TYPE POSTAL CODE"
        />
        <PrimaryButton onClick={handleSearch}>Search</PrimaryButton>
      </InputWrapper>
      <FilterWrapper>
        <input
          className="css-checkbox"
          id="filterRestaurant"
          type="checkbox"
          checked={filterRestaurant}
          onChange={handleFilterRestaurant}
        />
        <label for="filterRestaurant">Restaurant</label>
        <input
          className="css-checkbox"
          id="filterRetail"
          type="checkbox"
          checked={filterRetail}
          onChange={handleFilterRetail}
        />
        <label for="filterRetail">Retail</label>
      </FilterWrapper>
      <ListWrapper>
        {filteredPlaces.map(place => (
          <PlaceWrapper onClick={() => handlePlaceClick(place)}>
            <MarkerWrapper>
              <img src={MarkerImg} />
              <Distance>
                {place.distance ? `${place.distance} mi` : ""}
              </Distance>
            </MarkerWrapper>
            <DetailsWrapper>
              <PlaceTitle>{place.name}</PlaceTitle>
              <PlaceAddress>{richText(place.address?.raw)}</PlaceAddress>
              <PlaceType>{place.type}</PlaceType>
            </DetailsWrapper>
          </PlaceWrapper>
        ))}
      </ListWrapper>
    </Wrapper>
  )
}
